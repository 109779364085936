import React, { useState, useEffect } from "react";
import Paper from "@mui/material/Paper";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import TablePagination from "@mui/material/TablePagination";
import TableRow from "@mui/material/TableRow";
import Button from "@mui/material/Button";
import ButtonGroup from "@mui/material/ButtonGroup";
import PreviewIcon from "@mui/icons-material/Preview";
import DeleteIcon from "@mui/icons-material/Delete";
import Toolbar from "@mui/material/Toolbar";
import SearchIcon from "@mui/icons-material/Search";
import { Link } from "react-router-dom";
import moment from "moment";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { BASE_URL } from "../../global";
import PrintIcon from "@mui/icons-material/Print";
import HelpIcon from "@mui/icons-material/Help";
import Tooltip from "@mui/material/Tooltip";
import { Autocomplete, TextField } from "@mui/material";
import { AdapterDateFns } from "@mui/x-date-pickers/AdapterDateFns";
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
import { DatePicker } from "@mui/x-date-pickers/DatePicker";
import { showComponent } from "../helper/helpers";
import "../helper/helper.css";
import axios from "axios";
import { feedbackListGuide } from "./guide";

const _clientId = localStorage.getItem("clientId");
const userType = localStorage.getItem("type");

const columns = [
  { id: "id", label: "Sr. NO.", minWidth: 40 },
  { id: "date", label: "Date", minWidth: 110 },
  {
    id: "customerName",
    label: "Customer Name",
    minWidth: 40,
    align: "left",
  },
  {
    id: "address",
    label: "Customer Address",
    minWidth: 40,
    align: "left",
  },
  // {
  //   id: "contactPerson",
  //   label: "Contact Person",
  //   align: "left",
  //   minWidth: 100,
  // },

  {
    id: "csrfNumber",
    label: "CSRF Number",
    align: "left",
    minWidth: 40,
  },
  {
    id: "createdBy",
    label: "Created By",
    align: "left",
    minWidth: 40,
  },
  {
    id: "approvedby",
    label: "Approved By",
    align: "left",
    minWidth: 40,
  },
  
];

function createData(
  id,
  date,
  customerName,
  address,
  contactPerson,
  mobileNumber,
  csrfNumber,
  createdBy,
  approvedby,
  
) {
  return {
    id,
    date,
    customerName,
    address,
    contactPerson,
    mobileNumber,
    csrfNumber,
    createdBy,
    approvedby,
  };
}
const FeedbackList = (props) => {
  const feedbackListGuides = feedbackListGuide();
  const [page, setPage] = React.useState(0);
  const [rowsPerPage, setRowsPerPage] = React.useState(25);
  const [feedbackList, setFeedbackList] = React.useState([]);
  const [feedbackData, setFeedbackData] = React.useState([]);
  const [from, setFrom] = React.useState(null);
  const [to, setTo] = React.useState(null);
  const [users, setUsers] = React.useState([]);
  const [searchBy, setSearchBy] = React.useState("");
  const [searchKey, setSearchKey] = React.useState("");

  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(+event.target.value);
    setPage(0);
  };

  const getFeedbackList = (event) => {
    let whereConditions = "";
    let pagination_settings = "";

    if (searchBy !== "" && searchKey !== "") {
      let colName = {
        "Customer Name": "customerName",

      }[searchBy];
      whereConditions = `WHERE ${colName} LIKE '%${searchKey}%'`;
    }

    if (to != null && from != null) {
      if (whereConditions === "") {
        whereConditions = " WHERE ";
      } else {
        whereConditions += " AND ";
      }

      whereConditions += ` date BETWEEN '${moment(from).format(
        "YYYY-MM-DD"
      )}' AND '${moment(to).format("YYYY-MM-DD")}' `;
    }

    if (userType == "3") {
      whereConditions +=
        whereConditions.length > 0
          ? ` and (clientId=${_clientId})`
          : `where (clientId=${_clientId})`;
    }

    if (rowsPerPage !== -1) {
      pagination_settings = `LIMIT ${rowsPerPage} OFFSET ${
        rowsPerPage * Number(page)
      }`;
    }

    let data = {
      query: `SELECT * FROM customerFeedbackForm ${whereConditions} ORDER BY id DESC ${pagination_settings}`,
    };

  

    axios
      .post(BASE_URL + "dynamic", data)
      .then((res) => {
        setFeedbackList(res.data);
      })
      .catch((error) => {
        console.log(error);
      });
  };

  const submitSiteDelete = (rowIndex, id) => {
    props.setLoader(true);
    let newFeedbackData = [...feedbackData];

    if (newFeedbackData[rowIndex] != undefined) {
      axios
        .delete(BASE_URL + `customerFeedbackForm/${id}`)
        .then((res) => {
          toast.success("deleted successfully");
          props.setLoader(false);
          newFeedbackData.splice(rowIndex, 1);
          setFeedbackData([...newFeedbackData]);
        })
        .catch((err) => {
          console.log("err : ", err);
          toast.error("err : ", err);
          props.setLoader(false);
        });
    }
  };

  // api calls
  function getUsers() {
    const data = {
      query: "SELECT * FROM users",
    };
    axios
      .post(BASE_URL + `dynamic`, data)
      .then(async (res) => {
        setUsers(res.data);
      })
      .catch((err) => {
        console.log("clients data fetching error: ", err);
      });
  }

  const initiatFeedbackList = () => {
    var rowData = [];

    for (let i = 0; i < feedbackList.length; i++) {
      rowData.push(
        createData(
          feedbackList[i].id,
          feedbackList[i].date
            ? moment(feedbackList[i].date).format("DD-MM-YYYY")
            : "",
          feedbackList[i].customerName,
          feedbackList[i].address,
          feedbackList[i].contactPerson,
          feedbackList[i].mobileNumber,
          feedbackList[i].csrfNumber,
          feedbackList[i].createdBy,
          feedbackList[i].approvedby,
         
        )
      );
    }

    setFeedbackData(rowData);
  };

  const search = () => {
    getFeedbackList();
  };

  useEffect(() => {
    initiatFeedbackList();
  }, [feedbackList]);

  useEffect(() => {
    getFeedbackList();
    getUsers();
  }, []);

  return (
    <div>
      <TableContainer component={Paper} sx={{ mt: 2 }}>
      <div style={{display:"flex",justifyContent:"space-between"}}>            
        <Toolbar>
          {/* {showComponent("add") && ( */}
            <Button
            id="feedback_list_createbtn"
              variant="contained"
              size="small"
              component={Link}
              to="/editFeedback"
            >
              create CUSTOMER FEEDBACK
            </Button>
          {/* )} */}
        </Toolbar>
        <Tooltip title="User Guide" placement="top-start">
          <Button
          onClick={() => {
            feedbackListGuides.drive();
          }}
          >
            <HelpIcon />
          </Button>
        </Tooltip>
      </div>  
        <hr />
        <div
        className="mb-2"
        style={{
          display: "flex",
          float: "left",
          justifyContent: "flex-end",
          width: "100%",
        }}
      >
        <div
          className="mb-2"
          style={{ display: "flex", float: "left", justifyContent: "flex-end", marginRight: "10px" }}
        >
          <div style={{ marginLeft: "10px", marginRight: "10px" }} id="feedback_list_datefrom">
            <LocalizationProvider
              dateAdapter={AdapterDateFns}
              classNames="mr-2"
            >
              <DatePicker
                label="From"
                value={from ? new Date(from) : ""}
                inputFormat="dd/MM/yyyy"
                format="dd/MM/yyyy"
                onChange={(newValue) => setFrom(newValue)}
                renderInput={(params) => <TextField {...params} size="small" />}
              />
            </LocalizationProvider>
          </div>

          <div style={{ marginRight: "10px" }} id="feedback_list_dateto">
            <LocalizationProvider dateAdapter={AdapterDateFns}>
              <DatePicker
                label="To"
                value={to ? new Date(to) : ""}
                inputFormat="dd/MM/yyyy"
                format="dd/MM/yyyy"
                onChange={(newValue) => setTo(newValue)}
                renderInput={(params) => <TextField {...params} size="small" />}
                style={{ marginRight: "20px" }}
              />
            </LocalizationProvider>
          </div>

          <Autocomplete
            sx={{
              m: 0,
              minWidth: 180,
              marginLeft: "10px",
            }}
            size="small"
            id="feedback_list_searchby"
            options={[
              { key: "customerName", label: "Customer Name" },

            ]}
            renderInput={(params) => (
              <TextField {...params} label="Search By" />
            )}
            onInputChange={(event, newInputValue) => {
              setSearchBy(newInputValue);
            }}
          />
          <TextField
            id="feedback_list_searchbytext"
            label={"enter " + searchBy}
            size="small"
            variant="outlined"
            onChange={(e) => setSearchKey(e.target.value)}
          />
          <Button
          id="feedback_list_searchbtn"
            variant="contained"
            size="small"
            sx={{ m: 0 }}
            style={{ marginLeft: "10px" }}
            onClick={() => {
              search();
            }}
          >
            <SearchIcon />
          </Button>
        </div>
        </div>
        <br />

        <Table stickyHeader aria-label="sticky table" size="small" id="feedback_list_table">
          <TableHead>
            <TableRow>
              {columns.map((column) => (
                <TableCell
                  key={column.id}
                  align={column.align}
                  style={{ minWidth: `${column.minWidth}px` }}
                >
                  <b>{column.label}</b>
                </TableCell>
              ))}
              <TableCell>
                <b>Actions</b>
              </TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {feedbackData
              .slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
              .map((row, index) => {
                return (
                  <TableRow hover role="checkbox" tabIndex={-1} key={index}>
                    {columns.map((column) => {
                      // let value = row[column.id];
                      let value;

                      if (column.id === "createdBy" || column.id === "approvedby") {
                        let userObject = users.filter((e) => e.id === row[column.id]);
                        value = row[column.id] ? userObject[0]?.userName : null;
                      } else if (column.id === "contactPerson") {
                        value = (() => {
                          if (row[column.id]) {
                            const parsedData = JSON.parse(row[column.id])?.[0];
                            return (
                              <>
                                <h5 style={{ fontSize: "1em" }}> &nbsp;{parsedData?.contactPersonName}</h5>
                                {/* <h6 style={{ fontSize: "1em" }}>Contact: &nbsp;{parsedData?.contact}</h6>
                                <h6 style={{ fontSize: "1em" }}>Email: &nbsp;{parsedData?.emailId}</h6> */}
                              </>
                            );
                          }
                          return null;
                        })();
                      } else {
                        value = row[column.id];
                      }
                      
                      if (column.id == "id") {
                        value = page * rowsPerPage + index + 1;
                      }
                      return (
                        <TableCell key={column.id} align={column.align}>
                          {column.format && typeof value === "number"
                            ? column.format(value)
                            : value}
                        </TableCell>
                      );
                    })}

                    <TableCell>
                      <ButtonGroup
                        size="small"
                        aria-label="small button group"
                        className="action-container"
                      >
                        {showComponent("show") && (
                          <Tooltip title="Edit Feedback" placement="top-start">
                            <Button
                            id="feedback_list_edit"
                              style={{
                                borderColor: "#dc3545",
                              }}
                              component={Link}
                              to={`/editFeedback/${row.id}`}
                            >
                              <PreviewIcon />
                            </Button>
                          </Tooltip>
                        )}
                      {showComponent("show") && (
                          <Tooltip
                            title="Print Feedback"
                            placement="top-start"
                          >
                            <Button
                            id="feedback_list_downalod"
                              style={{
                                borderColor: "#dc3545",
                              }}
                              component={Link}
                              to={`/editFeedback/${row.id}/1`}
                            >
                              <PrintIcon />
                            </Button>
                          </Tooltip>
                        )}
                        {showComponent("delete") && (
                          <Tooltip title="Delete Feedback" placement="top-start">
                            <Button
                            id="feedback_list_delete"
                              onClick={(e) => {
                                if (
                                  window.confirm(
                                    "Really want to Delete Feedback?"
                                  )
                                ) {
                                  submitSiteDelete(
                                    page * rowsPerPage + index,
                                    row.id
                                  );
                                }
                              }}
                              style={{
                                borderColor: "#dc3545",
                              }}
                            >
                              <DeleteIcon style={{ color: "#dc3545" }} />
                            </Button>
                          </Tooltip>
                        )}
                      </ButtonGroup>
                    </TableCell>
                  </TableRow>
                );
              })}
          </TableBody>
        </Table>
        <TablePagination
          rowsPerPageOptions={[10, 25, 100]}
          component="div"
          count={feedbackData.length}
          rowsPerPage={rowsPerPage}
          page={page}
          onPageChange={handleChangePage}
          onRowsPerPageChange={handleChangeRowsPerPage}
        />
        <ToastContainer />
      </TableContainer>
    </div>
  );
}

export default FeedbackList