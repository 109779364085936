import React, { useEffect } from "react";
import { BASE_URL } from "../../../global";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import Paper from "@mui/material/Paper";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import TablePagination from "@mui/material/TablePagination";
import TableRow from "@mui/material/TableRow";
import Button from "@mui/material/Button";
import ButtonGroup from "@mui/material/ButtonGroup";
import PreviewIcon from "@mui/icons-material/Preview";
import DeleteIcon from "@mui/icons-material/Delete";
import { Toolbar, TextField, Autocomplete } from "@mui/material";
import { Link } from "react-router-dom";
import SearchIcon from "@mui/icons-material/Search";
import Tooltip from "@mui/material/Tooltip";
import { Table } from "@mui/material";
import HelpIcon from "@mui/icons-material/Help";
import { unitListGuide } from "./guide";
// datatablse packages
var $ = require("jquery");
$.DataTable = require("datatables.net");

const axios = require("axios");

const columns = [
  { id: "id", label: "Sr. No", minWidth: 40 },

  {
    id: "disciplineId",
    label: "Discipline",
    align: "left",
    minWidth: 40,
  },
  {
    id: "symbol",
    label: "Symbol",
    align: "left",
    minWidth: 40,
  },
];

function createData(id, disciplineId, symbol) {
  return {
    id,
    disciplineId,
    symbol,
  };
}

export default function UnitList() {
  const unitListGuides = unitListGuide();
  const [page, setPage] = React.useState(0);
  const [searchBy, setSearchBy] = React.useState("");
  const [searchKey, setSearchKey] = React.useState("");
  const [from, setFrom] = React.useState(null);
  const [to, setTo] = React.useState(null);
  const [rowsPerPage, setRowsPerPage] = React.useState(25);
  const [unitList, setUnitList] = React.useState([]);
  const [unitData, setUnitData] = React.useState([]);

  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(+event.target.value);
    setPage(0);
  };

  const submitSiteDelete = (id) => {
    var jsonData = {
      status: -1,
    };
    let url = BASE_URL;
    axios
      .patch(url + `unit/${id}`, jsonData)
      .then((res) => {
        toast("Unit was deleted!");
        setTimeout(refresh, 500);
      })
      .catch((error) => {
        toast.error("Something Went Wrong!");
      });
  };

  var refresh = () => {
    window.location.reload(false);
    // <Link to="/master/master/standard">Home</Link>
  };

  const getUnitList = (event) => {
    let url = BASE_URL;
    const payload1 = {
      query: "SELECT unit.*, discipline.name AS disciplineId FROM unit JOIN discipline ON unit.disciplineId = discipline.id"
    };
    axios
    .post(url + "dynamic", payload1)
      // .get(
      //   url +
      //     "xjoin?_join=units.unit,_j,disc.discipline&_on1=(units.disciplineId,eq,disc.id)&_fields=units.symbol,disc.name,units.id&_where=(units.status,eq,1)"
      // )
      .then((res) => {
        console.log("res",res.data)
        setUnitList(res.data);
        initializeDataTable();
      })
      .catch((error) => {
        console.log(error);
      });
  };

  const search = async () => {
    let colName = {
      "Discipline Name": "discipline.name",
    }[searchBy];
    let query = {
      query: `SELECT unit.*, discipline.name AS disciplineId FROM unit JOIN discipline ON unit.disciplineId = discipline.id WHERE ${colName} LIKE '%${searchKey}%'`,
    };
    try {
      const response = await axios.post(BASE_URL + `dynamic`, query);
      console.log(response.data)
      setUnitList(response.data)
    } catch (error) {
      console.error(error);
    }
  };

  const initializeDataTable = () => {
    $("#datatable-keytable").DataTable({
      destroy: true,
      keys: true,
      dom:
        "<'row mb-2'<'col-sm-9' B><'col-sm-3' >>" +
        "<'row mb-2'<'col-sm-9' l><'col-sm-3' f>>" +
        "<'row'<'col-sm-12' tr>>" +
        "<'row'<'col-sm-7 mt-2 mr-5 pr-4'i><'ml-5' p>>",
      bPaginate: false,
      bLengthChange: true,
      bFilter: true,
      bInfo: false,
      bAutoWidth: true,
      language: {
        zeroRecords: " ",
      },

      buttons: ["copy", "csv", "excel", "print"],
    });
  };

  const initializeUnitData = () => {
    var rowDaata = [];
    for (let i = 0; i < unitList.length; i++) {
      rowDaata.push(
        createData(
          unitList[i].units_id,
          unitList[i].disc_name,
          unitList[i].units_symbol
        )
      );
    }
    setUnitData(rowDaata);
  };

  useEffect(() => {
    getUnitList();
  }, []);

  useEffect(() => {
    initializeUnitData();
  }, [unitList]);

  return (
    <TableContainer component={Paper} sx={{ mt: 2 }}>
      <div style={{ display: "flex", justifyContent: "space-between" }}>
        <Toolbar>
          <Button
            id="unit_list_createbtn"
            variant="contained"
            size="small"
            component={Link}
            to="/master/unit/createUnit"
            onClick={() => {
              {
              }
            }}
          >
            <b>create UNIT</b>
          </Button>
        </Toolbar>
        <Tooltip title="User Guide" placement="top-start">
          <Button
            onClick={() => {
              unitListGuides.drive();
            }}
          >
            <HelpIcon />
          </Button>
        </Tooltip>
      </div>
      <br />
      <div
        className="mb-2"
        style={{
          display: "flex",
          float: "left",
          justifyContent: "flex-end",
          width: "100%",
        }}
      >
        <div
          className="mb-2"
          style={{
            display: "flex",
            float: "left",
            justifyContent: "flex-end",
            marginRight: "10px",
          }}
        >
          <br />

          <Autocomplete
            sx={{
              m: 0,
              minWidth: 180,
              marginLeft: "10px",
            }}
            size="small"
            id="client_list_searchby"
            options={[{ key: "instrumentId", label: "Discipline Name" }]}
            renderInput={(params) => (
              <TextField {...params} label="Search By" />
            )}
            onInputChange={(event, newInputValue) => {
              setSearchBy(newInputValue);
            }}
          />
          <TextField
            id="client_list_searchbytext"
            label={"enter " + searchBy}
            size="small"
            variant="outlined"
            onChange={(e) => setSearchKey(e.target.value)}
          />
          <Button
            id="client_list_searchbtn"
            variant="contained"
            size="small"
            sx={{ m: 0 }}
            style={{ marginLeft: "10px" }}
            onClick={() => {
              search();
            }}
          >
            <SearchIcon />
          </Button>
        </div>
      </div>
      <br />

      <Table
        stickyHeader
        aria-label="sticky table"
        size="small"
        id="unit_list_table"
      >
        <TableHead>
          <TableRow>
            {columns.map((column) => (
              <TableCell
                key={column.id}
                align={column.align}
                style={{ minWidth: column.minWidth }}
              >
                <b>{column.label}</b>
              </TableCell>
            ))}
            <TableCell>
              <b>Actions</b>
            </TableCell>
          </TableRow>
        </TableHead>
        <TableBody>
          {unitList
            .slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
            .map((row, index) => {
              return (
                <TableRow hover role="checkbox" tabIndex={-1} key={index}>
                  {columns.map((column) => {
                    let value = row[column.id];

                    if (column.id == "id") {
                      value = page * rowsPerPage + index + 1;
                    }
                    return (
                      <TableCell key={column.id} align={column.align}>
                        {column.format && typeof value === "number"
                          ? column.format(value)
                          : value}
                      </TableCell>
                    );
                  })}

                  <TableCell>
                    <ButtonGroup size="small" aria-label="small button group">
                      <Tooltip title="Edit Unit" placement="top-start">
                        <Button
                          id="unit_list_edit"
                          component={Link}
                          to={`/master/unit/EditUnit/${row.id}`}
                        >
                          <PreviewIcon />
                        </Button>
                      </Tooltip>
                      <Tooltip title="Delete Unit" placement="top-start">
                        <Button
                          id="unit_list_delete"
                          onClick={(e) => {
                            if (window.confirm("Really want to delete Unit?")) {
                              submitSiteDelete(row.id);
                            }
                          }}
                        >
                          <DeleteIcon style={{ color: "#dc3545" }} />
                        </Button>
                      </Tooltip>
                    </ButtonGroup>
                  </TableCell>
                </TableRow>
              );
            })}
        </TableBody>
      </Table>
      <TablePagination
        rowsPerPageOptions={[10, 25, 100]}
        component="div"
        count={unitList.length}
        rowsPerPage={rowsPerPage}
        page={page}
        onPageChange={handleChangePage}
        onRowsPerPageChange={handleChangeRowsPerPage}
      />

      <ToastContainer />
    </TableContainer>
  );
}
