import React, { useEffect, useState } from "react";
import {
  Grid,
  Typography,
  Card,
  CardContent,
  CardActions,
  Button,
  Box,
  CardMedia,
  TableContainer,
  Paper,
  Chip,
  Divider,
  Tooltip,
} from "@mui/material";

import GroupIcon from "@mui/icons-material/Group";
import CardGiftcardOutlinedIcon from "@mui/icons-material/CardGiftcardOutlined";
import PrecisionManufacturingOutlinedIcon from "@mui/icons-material/PrecisionManufacturingOutlined";
import RotateLeftOutlinedIcon from "@mui/icons-material/RotateLeftOutlined";
import ArticleIcon from "@mui/icons-material/Article";
import HelpIcon from "@mui/icons-material/Help";
import Analytics from "./Analytics";
import { BASE_URL } from "./../../global";
import Tab from "react-bootstrap/Tab";
import Tabs from "react-bootstrap/Tabs";
import WorkReport from "../dashboard/workReport";
import PaymentReport from "../dashboard/paymentReport";
import StdInOut from "../dashboard/stdInOut";
import Revenue from "../dashboard/revenue";
import Dashboard_background from "../image/dashboard.jpg";
import { dashboardGuide } from "./guide";
// const userType = localStorage.getItem('type')
const axios = require("axios");

export default function Dashboard() {
  const dashboardGuides = dashboardGuide();
  const [clientCount, setClientCount] = React.useState("");
  const [certificateCount, setCertificateCount] = React.useState("");
  const [instrumentCount, setInstrumentCount] = React.useState("");
  const [todayClientCount, setTodayClientCount] = React.useState("");
  const [srfCount, setSrfCount] = React.useState("");
  const [todaysCertificateCount, setTodaysCertificateCount] =
    React.useState("");
  const [renewal, setRenewal] = React.useState(0);
  const [userType, setUserType] = React.useState(localStorage.getItem("type"));
  const [counts, setCounts] = useState({
    todaysClientsCount: 0,
    todaysSrfsCount: 0,
    todaysCertificatesCount: 0,
    todaysRenewalsCount: 0,
    overAllActiveIncCount: 0,
    overAllRenewalCount: 0,
  });

// console.log("counts",counts)

  const getClientCount = (event) => {
    let url = BASE_URL;
    axios
      .get(url + "clients/count?_where=(status,eq,1)")
      .then((res) => {
        setClientCount(res.data[0].no_of_rows);
      })
      .catch((error) => {
        console.log(error);
      });
  };

  const getCertificateCount = (event) => {
    let url = BASE_URL;
    axios
      .get(url + "certificates/count?_where=(status,eq,1)")
      .then((res) => {
        setCertificateCount(res.data[0].no_of_rows);
      })
      .catch((error) => {
        console.log(error);
      });
  };

  const getInstrumentCount = (event) => {
    let url = BASE_URL;
    axios
      .get(url + "instruments/count?_where=(status,eq,1)")
      .then((res) => {
        setInstrumentCount(res.data[0].no_of_rows);
      })
      .catch((error) => {
        console.log(error);
      });
  };

  const getTodayClientCount = (event) => {
    let url = BASE_URL;
    axios
      .get(url + "clients/count?_where=(status,eq,1)")
      .then((res) => {
        setTodayClientCount(res.data[0].no_of_rows);
      })
      .catch((error) => {
        console.log(error);
      });
  };

  const getSrfCount = (event) => {
    let url = BASE_URL;
    axios
      .get(url + "srfs/count?_where=(status,eq,1)")
      .then((res) => {
        setSrfCount(res.data[0].no_of_rows);
      })
      .catch((error) => {
        console.log(error);
      });
  };

  const getTodayCertificateCount = (event) => {
    let url = BASE_URL;
    axios
      .get(url + "certificateStaticTables/count?_where=(status,eq,1)")
      .then((res) => {
        setTodaysCertificateCount(res.data[0].no_of_rows);
      })
      .catch((error) => {
        console.log(error);
      });
  };

  const getRenewal = (event) => {
    let url = BASE_URL;
    axios
      .get(url + "certificates")
      .then((res) => {
        var tomorrow = new Date();
        tomorrow.setDate(tomorrow.getDate() + 7);
        if (res.data && res.data.length)
          var resultProductData = res.data?.filter(
            (data) =>
              new Date(data.nextDueDate) <= new Date(tomorrow) &&
              data.nextDueDate != null
          );
        setRenewal(resultProductData.length);
      })
      .catch((error) => {
        console.log(error);
      });
  };

  useEffect(() => {
    getClientCount();
    getCertificateCount();
    getInstrumentCount();
    getTodayClientCount();
    getSrfCount();
    getTodayCertificateCount();
    getRenewal();
  }, []);



/////////////////////////////DashboardData////////////////////////



const fetchCount = async (table, dateColumn, condition) => {
  try {
    const url = `${BASE_URL}dynamic`;
    const query = `SELECT * FROM ${table} WHERE DATE(${dateColumn}) ${condition}`;
    const response = await axios.post(url, { query });
    return response.data.length;
  } catch (error) {
    console.error(error);
    return 0;
  }
};

const fetchDataCounts = async () => {
  const todaysClientsCount = await fetchCount('clients', 'lastModified', '= CURDATE()');
  const todaysSrfsCount = await fetchCount('srfs', 'lastModified', '= CURDATE()');
  const todaysCertificatesCount = await fetchCount('certificates', 'lastModified', '= CURDATE()');
  const todaysRenewalsCount = await fetchCount('certificates', 'nextDueDate', '= CURDATE()');
  const overAllActiveIncCount = await fetchCount('certificates', 'nextDueDate', '<= CURDATE()');
  const overAllRenewalCount = await fetchCount('certificates', 'nextDueDate', '>= CURDATE()');

  setCounts({
    todaysClientsCount,
    todaysSrfsCount,
    todaysCertificatesCount,
    todaysRenewalsCount,
    overAllActiveIncCount,
    overAllRenewalCount,
  });
};

useEffect(() => {
  fetchDataCounts();
}, []);


  if (userType != 2 && userType != 3)

    return (
      <div
        style={{
          width: "100%",
          height: "100%",
        }}
      >
        <div style={{display:"flex",justifyContent:"flex-end"}}>            
          <Tooltip title="User Guide" placement="top-start">
            <Button
            onClick={() => {
              dashboardGuides.drive();
            }}
            >
              <HelpIcon />
            </Button>
          </Tooltip>
        </div>  
        <div component={Paper} sx={{ mt: 2 }}>
          <Divider sx={{ mt: 5 }}>
            <Chip
              label="OVERALL"
              variant="outlined"
              sx={{ fontSize: "16px" }}
              style={{ fontWeight: "bold" }}
            />
          </Divider>
          <Grid container spacing={3}>
            <Grid item xs={3}>
              <Card
              id="dashboard_clients"
                className="cardContent"
                sx={{
                  m: 2,
                  p: 1,
                  display: "flex",
                  border: 1,
                  borderColor: "#283593",
                }}
                style={{ height: "150px" }}
              >
                <GroupIcon
                  sx={{ color: "#283593" }}
                  style={{ fontSize: "100px" }}
                />
                <Box
                  sx={{
                    display: "flex",
                    flexDirection: "column",
                  }}
                >
                  <CardContent sx={{ flex: "1 0 auto" }}>
                    <Typography>Clients</Typography>
                    <Typography
                      component="div"
                      variant="h4"
                      sx={{
                        fontWeight: "bold",
                        color: "#283593",
                      }}
                    >
                      {clientCount}
                    </Typography>
                  </CardContent>
                  <Box
                    sx={{
                      display: "flex",
                      alignItems: "center",
                      pl: 1,
                      pb: 1,
                    }}
                  >
                    {/* <Button size="small" variant="outlined">
                                        view more
                                    </Button> */}
                  </Box>
                </Box>
              </Card>
            </Grid>
            <Grid item xs={3}>
              <Card
              id="dashboard_certificates"
                className="cardContent"
                sx={{
                  m: 2,
                  p: 1,
                  display: "flex",
                  border: 1,
                  borderColor: "#283593",
                }}
                style={{ height: "150px" }}
              >
                <CardGiftcardOutlinedIcon
                  sx={{ color: "#283593" }}
                  style={{ fontSize: "100px" }}
                />
                <Box
                  sx={{
                    display: "flex",
                    flexDirection: "column",
                  }}
                >
                  <CardContent sx={{ flex: "1 0 auto" }}>
                    <Typography>Certificates</Typography>
                    <Typography
                      component="div"
                      variant="h4"
                      sx={{
                        fontWeight: "bold",
                        color: "#283593",
                      }}
                    >
                      {certificateCount}
                    </Typography>
                  </CardContent>
                  <Box
                    sx={{
                      display: "flex",
                      alignItems: "center",
                      pl: 1,
                      pb: 1,
                    }}
                  >
                    {/* <Button size="small" variant="outlined">
                                        view more
                                    </Button> */}
                  </Box>
                </Box>
              </Card>
            </Grid>
            <Grid item xs={3}>
              <Card
              id="dashboard_active_Ins"
                className="cardContent"
                sx={{
                  m: 2,
                  p: 1,
                  display: "flex",
                  border: 1,
                  borderColor: "#283593",
                }}
                style={{ height: "150px" }}
              >
                <PrecisionManufacturingOutlinedIcon
                  sx={{ color: "#283593" }}
                  style={{ fontSize: "100px" }}
                />
                <Box
                  sx={{
                    display: "flex",
                    flexDirection: "column",
                  }}
                >
                  <CardContent sx={{ flex: "1 0 auto" }}>
                    <Typography>Active Ins.</Typography>
                    <Typography
                      component="div"
                      variant="h4"
                      sx={{
                        fontWeight: "bold",
                        color: "#283593",
                      }}
                    >
                      {counts.overAllActiveIncCount}
                    </Typography>
                  </CardContent>
                  <Box
                    sx={{
                      display: "flex",
                      alignItems: "center",
                      pl: 1,
                      pb: 1,
                    }}
                  >
                    {/* <Button size="small" variant="outlined">
                                        view more
                                    </Button> */}
                  </Box>
                </Box>
              </Card>
            </Grid>
            <Grid item xs={3}>
              <Card
              id="dashboard_renewal"
                className="cardContent"
                sx={{
                  m: 2,
                  p: 1,
                  display: "flex",
                  border: 1,
                  borderColor: "#283593",
                }}
                style={{ height: "150px" }}
              >
                <RotateLeftOutlinedIcon
                  sx={{ color: "#283593" }}
                  style={{ fontSize: "100px" }}
                />
                <Box
                  sx={{
                    display: "flex",
                    flexDirection: "column",
                  }}
                >
                  <CardContent sx={{ flex: "1 0 auto" }}>
                    <Typography>renewal</Typography>
                    <Typography
                      component="div"
                      variant="h4"
                      sx={{
                        fontWeight: "bold",
                        color: "#283593",
                      }}
                    >
                      {counts.overAllRenewalCount}
                    </Typography>
                  </CardContent>
                  <Box
                    sx={{
                      display: "flex",
                      alignItems: "center",
                      pl: 1,
                      pb: 1,
                    }}
                  >
                    {/* <Button size="small" variant="outlined">
                                        view more
                                    </Button> */}
                  </Box>
                </Box>
              </Card>
            </Grid>
          </Grid>
        </div>
        <div component={Paper} sx={{ mt: 5 }}>
          <Divider sx={{ mt: 5 }}>
            <Chip
              label="TODAY'S"
              variant="outlined"
              sx={{ fontSize: "16px" }}
              style={{ fontWeight: "bold" }}
            />
          </Divider>
          <Grid container spacing={3}>
            <Grid item xs={3}>
              <Card
              id="dashboard_todayclients"
                className="cardContent"
                sx={{
                  m: 2,
                  p: 1,
                  display: "flex",
                  border: 1,
                  borderColor: "#ff5722",
                }}
                style={{ height: "150px" }}
              >
                <GroupIcon
                  sx={{ color: "#ff5722" }}
                  style={{ fontSize: "100px" }}
                />
                <Box
                  sx={{
                    display: "flex",
                    flexDirection: "column",
                  }}
                >
                  <CardContent sx={{ flex: "1 0 auto" }}>
                    <Typography>New clients</Typography>
                    <Typography
                      component="div"
                      variant="h4"
                      sx={{
                        fontWeight: "bold",
                        color: "#ff5722",
                      }}
                    >
                      {counts.todaysClientsCount}
                    </Typography>
                  </CardContent>
                  <Box
                    sx={{
                      display: "flex",
                      alignItems: "center",
                      pl: 1,
                      pb: 1,
                    }}
                  >
                    {/* <Button size="small" variant="outlined" className="but">
                                        view more
                                    </Button> */}
                  </Box>
                </Box>
              </Card>
            </Grid>{" "}
            <Grid item xs={3}>
              <Card
              id="dashboard_SRF"
                className="cardContent"
                sx={{
                  m: 2,
                  p: 1,
                  display: "flex",
                  border: 1,
                  borderColor: "#ff5722",
                }}
                style={{ height: "150px" }}
              >
                <ArticleIcon
                  sx={{ color: "#ff5722" }}
                  style={{ fontSize: "100px" }}
                />
                <Box
                  sx={{
                    display: "flex",
                    flexDirection: "column",
                  }}
                >
                  <CardContent sx={{ flex: "1 0 auto" }}>
                    <Typography>New SRF</Typography>
                    <Typography
                      component="div"
                      variant="h4"
                      sx={{
                        fontWeight: "bold",
                        color: "#ff5722",
                      }}
                    >
                      {counts.todaysSrfsCount}
                    </Typography>
                  </CardContent>
                  <Box
                    sx={{
                      display: "flex",
                      alignItems: "center",
                      pl: 1,
                      pb: 1,
                    }}
                  >
                    {/* <Button size="small" variant="outlined" className="but">
                                        view more
                                    </Button> */}
                  </Box>
                </Box>
              </Card>
            </Grid>
            <Grid item xs={3}>
              <Card
              id="dashboard_todaycertificates"
                className="cardContent"
                sx={{
                  m: 2,
                  p: 1,
                  display: "flex",
                  border: 1,
                  borderColor: "#ff5722",
                }}
                style={{ height: "150px" }}
              >
                <CardGiftcardOutlinedIcon
                  sx={{ color: "#ff5722" }}
                  style={{ fontSize: "100px" }}
                />
                <Box
                  sx={{
                    display: "flex",
                    flexDirection: "column",
                  }}
                >
                  <CardContent sx={{ flex: "1 0 auto" }}>
                    <Typography>Certificates</Typography>
                    <Typography
                      component="div"
                      variant="h4"
                      sx={{
                        fontWeight: "bold",
                        color: "#ff5722",
                      }}
                    >
                      {counts.todaysCertificatesCount}
                    </Typography>
                  </CardContent>
                  <Box
                    sx={{
                      display: "flex",
                      alignItems: "center",
                      pl: 1,
                      pb: 1,
                    }}
                  >
                    {/* <Button size="small" variant="outlined" className="but">
                                        view more
                                    </Button> */}
                  </Box>
                </Box>
              </Card>
            </Grid>
            <Grid item xs={3}>
              <Card
              id="dashboard_todayrenewal"
                className="cardContent"
                sx={{
                  m: 2,
                  p: 1,
                  display: "flex",
                  border: 1,
                  borderColor: "#ff5722",
                }}
                style={{ height: "150px" }}
              >
                <RotateLeftOutlinedIcon
                  sx={{ color: "#ff5722" }}
                  style={{ fontSize: "100px" }}
                />
                <Box
                  sx={{
                    display: "flex",
                    flexDirection: "column",
                  }}
                >
                  <CardContent sx={{ flex: "1 0 auto" }}>
                    <Typography>Renewals</Typography>
                    <Typography
                      component="div"
                      variant="h4"
                      sx={{
                        fontWeight: "bold",
                        color: "#ff5722",
                      }}
                    >
                     {counts.todaysRenewalsCount}
                    </Typography>
                  </CardContent>
                  <Box
                    sx={{
                      display: "flex",
                      alignItems: "center",
                      pl: 1,
                      pb: 1,
                    }}
                  >
                    {/* <Button size="small" variant="outlined" className="but">
                                        view more
                                    </Button> */}
                  </Box>
                </Box>
              </Card>
            </Grid>
          </Grid>
        </div>
        <div component={Paper} sx={{ mt: 5 }}>
          <Divider sx={{ mt: 5 }}>
            <Chip
              label="ANALYTICS"
              variant="outlined"
              sx={{ fontSize: "16px" }}
              style={{ fontWeight: "bold" }}
            />
          </Divider>
          <Tabs
            defaultActiveKey="profile"
            id="uncontrolled-tab-example"
            className="mb-3"
          >

            <Tab eventKey="profile" title="Commercial Today's Report">
              <PaymentReport />
            </Tab>
            <Tab eventKey="contact" title="Standard Out-in">
              <StdInOut />
            </Tab>
          </Tabs>
        </div>
      </div>
    );
  else {
    return (
      <div
        style={{
          width: "100%",
          height: "100%",
          display: "flex",
          flexDirection: "column",
          alignItems: "center",
          justifyContent: "center",
        }}
      >
        <img
          style={{
            height: "auto",
            maxHeight: "100%",
            maxWidth: "100%",
            width: "1250px",
            objectFit: "contain",
          }}
          src={Dashboard_background}
          alt="dashboard_background"
        />
      </div>
    );
  }
}
