import React, { useEffect } from "react";
import Paper from "@mui/material/Paper";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import TablePagination from "@mui/material/TablePagination";
import TableRow from "@mui/material/TableRow";
import Button from "@mui/material/Button";
import ButtonGroup from "@mui/material/ButtonGroup";
import PreviewIcon from "@mui/icons-material/Preview";
import DeleteIcon from "@mui/icons-material/Delete";
import Toolbar from "@mui/material/Toolbar";
import SearchIcon from "@mui/icons-material/Search";
import { Link } from "react-router-dom";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { BASE_URL } from "../../../global";
import Tooltip from "@mui/material/Tooltip";
import HelpIcon from "@mui/icons-material/Help";
import { productListGuide } from "./guide";
import { Autocomplete, TextField } from "@mui/material";
import moment from "moment";
// datatablse packages
var $ = require("jquery");
$.DataTable = require("datatables.net");

const axios = require("axios");

const columns = [
  { id: "id", label: "Sr. No.", minWidth: 10 },
  { id: "instrumentId", label: "Product Name", minWidth: 40 },
  { id: "rate", label: "Rate", minWidth: 40 },
];

function createData(id, instrumentId, rate) {
  return {
    id,
    instrumentId,
    rate,
  };
}

export default function ProductsList() {
  const productListGuides = productListGuide();
  const [page, setPage] = React.useState(0);
  const [searchBy, setSearchBy] = React.useState("");
  const [searchKey, setSearchKey] = React.useState("");
  const [from, setFrom] = React.useState(null);
  const [to, setTo] = React.useState(null);
  const [rowsPerPage, setRowsPerPage] = React.useState(10);
  const [productsList, setProductList] = React.useState([]);
  const [instrumentArray, setInstrumentArray] = React.useState([]);
  const [rowDaata, setRowData] = React.useState([]);
  const [pushIArray, setPushIArray] = React.useState([]);

  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(+event.target.value);
    setPage(0);
  };

  const submitProductDelete = (id) => {
    let url = BASE_URL;
    axios
      .delete(url + `customProducts/${id}`)
      .then((res) => {
        toast("products deleted!");
        setTimeout(refresh, 500);
      })
      .catch((error) => {
        toast.error("Something Went Wrong!");
      });
  };

  var refresh = () => {
    window.location.reload(false);
  };

  const getProductList = (event) => {
    // let data = getQuery();
    let url = BASE_URL;
    // const payload = {
    //   query: "SELECT * FROM customProducts ORDER BY id DESC;",
    // };
    const payload1 = {
      query: "SELECT cp.*, i.instrumentName FROM customProducts cp JOIN instruments i ON cp.instrumentId = i.id ORDER BY cp.id DESC;",
    };

    axios
      .post(url + "dynamic", payload1)
      .then((res) => {
        console.log(res.data);
        setProductList(res.data);
        initializeDataTable();
      })
      .catch((error) => {
        console.log(error);
      });
  };

  const search = async () => {
    let colName = {
      "Product Name": "i.instrumentName",
    }[searchBy];
    let query = {
      query: `SELECT cp.*, i.instrumentName FROM customProducts cp JOIN instruments i ON cp.instrumentId = i.id WHERE ${colName} LIKE '%${searchKey}%' ORDER BY cp.id DESC`,
    };
    try {
      const response = await axios.post(BASE_URL + `dynamic`, query);
      console.log(response.data)
      setProductList(response.data)
    } catch (error) {
      console.error(error);
    }
  };

  const generateRowData = (productsList) => {
    const newRowData = productsList.map((product) => {
      return createData(
        product.id,
        product.instrumentId,
        product.rate
      );
    });

    setRowData(newRowData);
  };

  const initializeDataTable = () => {
    $("#datatable-keytable").DataTable({
      destroy: true,
      keys: true,
      dom:
        "<'row mb-2'<'col-sm-9' B><'col-sm-3' >>" +
        "<'row mb-2'<'col-sm-9' l><'col-sm-3' f>>" +
        "<'row'<'col-sm-12' tr>>" +
        "<'row'<'col-sm-7 mt-2 mr-5 pr-4'i><'ml-5' p>>",
      bPaginate: false,
      bLengthChange: true,
      bFilter: true,
      bInfo: false,
      bAutoWidth: true,
      language: {
        zeroRecords: " ",
      },

      buttons: ["copy", "csv", "excel", "print"],
    });
  };

  const getInstrumentsList = () => {
    let url = BASE_URL;
    axios
      .get(url + "instruments")
      .then((res) => {
        setInstrumentArray(res.data);
      })
      .catch((error) => {
        toast.error("Something Went Wrong!");
      });
  };

  const generatePushIArray = (instrumentArray) => {
    const newPushIArray = instrumentArray
      .filter((instrument) => instrument.instrumentName)
      .map((instrument) => {
        return {
          id: instrument.id,
          label: instrument.id + ", " + instrument.instrumentName,
        };
      });

    setPushIArray(newPushIArray);
  };

  useEffect(() => {
    generatePushIArray(instrumentArray);
  }, [instrumentArray]);

  useEffect(() => {
    generateRowData(productsList);
  }, [productsList]);

  useEffect(() => {
    getProductList();
    getInstrumentsList();
  }, []);

  return (
    <TableContainer component={Paper} sx={{ mt: 2 }}>
      
      <div style={{display:"flex",justifyContent:"space-between"}}>            
      <Toolbar>
        <Button
        id="product_list_createbtn"
          variant="contained"
          size="small"
          component={Link}
          to="/master/product/createProduct"
        >
          <b>create PRODUCT</b>
        </Button>
      </Toolbar>
      <Tooltip title="User Guide" placement="top-start">
        <Button
         onClick={() => {
          productListGuides.drive();
         }}
        >
          <HelpIcon />
        </Button>
      </Tooltip>
    </div> 
    <br/>
    <div
        className="mb-2"
        style={{
          display: "flex",
          float: "left",
          justifyContent: "flex-end",
          width: "100%",
        }}
      >
      <div
        className="mb-2"
        style={{ display: "flex", float: "left", justifyContent: "flex-end", marginRight: "10px" }}
      >
        <br />

        <Autocomplete
          sx={{
            m: 0,
            minWidth: 180,
            marginLeft: "10px",
          }}
          size="small"
          id="client_list_searchby"
          options={[
            { key: "instrumentId", label: "Product Name" },
          ]}
          renderInput={(params) => <TextField {...params} label="Search By" />}
          onInputChange={(event, newInputValue) => {
            setSearchBy(newInputValue);
          }}
        />
        <TextField
          id="client_list_searchbytext"
          label={"enter " + searchBy}
          size="small"
          variant="outlined"
          onChange={(e) => setSearchKey(e.target.value)}
        />
        <Button
        id="client_list_searchbtn"
          variant="contained"
          size="small"
          sx={{ m: 0 }}
          style={{ marginLeft: "10px" }}
          onClick={() => {
            search();
          }}
        >
          <SearchIcon />
        </Button>
      </div>
      </div>
      <br />

      <Table id="product_list_table" stickyHeader aria-label="sticky table" size="small">
        <TableHead>
          <TableRow>
            {columns.map((column) => (
              <TableCell
                key={column.id}
                align={column.align}
                style={{ minWidth: column.minWidth }}
              >
                <b>{column.label}</b>
              </TableCell>
            ))}
            <TableCell>
              <b>Actions</b>
            </TableCell>
          </TableRow>
        </TableHead>
        <TableBody>
          {productsList
            .slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
            .map((row, index) => {
              return (
                <TableRow hover role="checkbox" tabIndex={-1} key={index}>
                  {columns.map((column) => {
                    let value;

                    if (column.id == "instrumentId") {
                      const label = pushIArray.find(
                        (e) => e?.label?.split(",")[0] == row[column.id]
                      )?.label;
                      const newValue = label ? label.split(",")[1] : "";
                      value = newValue;
                    } else {
                      value = row[column.id];
                    }

                    if (column.id == "id") {
                      value = page * rowsPerPage + index + 1;
                    }

                    return (
                      <TableCell key={column.id} align={column.align}>
                        {column.format && typeof value === "number"
                          ? column.format(value)
                          : value}
                      </TableCell>
                    );
                  })}

                  <TableCell>
                    <ButtonGroup size="small" aria-label="small button group">
                      <Tooltip title="Edit Product" placement="top-start">
                        <Button
                        id="product_list_edit"
                          style={{
                            borderColor: "#dc3545",
                          }}
                          component={Link}
                          to={`/master/editProduct/${row.id}`}
                        >
                          <PreviewIcon />
                        </Button>
                      </Tooltip>
                      <Tooltip title="Delete Product" placement="top-start">
                        <Button
                        id="product_list_delete"
                          onClick={(e) => {
                            if (
                              window.confirm("Really want to delete product?")
                            ) {
                              submitProductDelete(row.id);
                            }
                          }}
                          style={{
                            borderColor: "#dc3545",
                          }}
                        >
                          <DeleteIcon style={{ color: "#dc3545" }} />
                        </Button>
                      </Tooltip>
                    </ButtonGroup>
                  </TableCell>
                </TableRow>
              );
            })}
        </TableBody>
      </Table>
      <TablePagination
        rowsPerPageOptions={[10, 25, 100]}
        component="div"
        count={productsList.length}
        rowsPerPage={rowsPerPage}
        page={page}
        onPageChange={handleChangePage}
        onRowsPerPageChange={handleChangeRowsPerPage}
      />

      {/* <Table
        stickyHeader
        aria-label="sticky table"
        size="small"
        id="datatable-keytable"
      >
        <TableHead>
          <TableRow>
            {columns.map((column) => (
              <TableCell
                key={column.id}
                align={column.align}
                style={{ minWidth: column.minWidth }}
              >
                <b>{column.label}</b>
              </TableCell>
            ))}
            <TableCell>
              <b>Actions</b>
            </TableCell>
          </TableRow>
        </TableHead>
        <TableBody>
          {rowDaata
            .slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
            .map((row, index) => {
              return (
                <TableRow hover role="checkbox" tabIndex={-1} key={row.code}>
                  {columns.map((column) => {
                    let value = row[column.id];
                    if (column.id == "id") {
                      value = page * rowsPerPage + index + 1;
                    }
                    return (
                      <TableCell key={column.id} align={column.align}>
                        {column.format && typeof value === "number"
                          ? column.format(value)
                          : value}
                      </TableCell>
                    );
                  })}
                  <TableCell>
                    <ButtonGroup size="small" aria-label="small button group">
                      <Tooltip title="Edit Product" placement="top-start">
                        <Button
                          style={{
                            borderColor: "#dc3545",
                          }}
                          component={Link}
                          to={`/master/editProduct/${row.id}`}
                        >
                          <PreviewIcon />
                        </Button>
                      </Tooltip>
                      <Tooltip title="Delete Product" placement="top-start">
                        <Button
                          onClick={(e) => {
                            if (
                              window.confirm("Really want to delete product?")
                            ) {
                              submitProductDelete(row.id);
                            }
                          }}
                          style={{
                            borderColor: "#dc3545",
                          }}
                        >
                          <DeleteIcon style={{ color: "#dc3545" }} />
                        </Button>
                      </Tooltip>
                    </ButtonGroup>
                  </TableCell>
                </TableRow>
              );
            })}
        </TableBody>
      </Table>
      <TablePagination
        rowsPerPageOptions={[10, 25, 100]}
        component="div"
        count={rowDaata.length}
        rowsPerPage={rowsPerPage}
        page={page}
        onPageChange={handleChangePage}
        onRowsPerPageChange={handleChangeRowsPerPage}
      /> */}
      <ToastContainer />
    </TableContainer>
  );
}
