import React, { useEffect } from "react";
import { BASE_URL } from "../../../global";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import Paper from "@mui/material/Paper";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import SearchIcon from "@mui/icons-material/Search";
import TableHead from "@mui/material/TableHead";
import TablePagination from "@mui/material/TablePagination";
import TableRow from "@mui/material/TableRow";
import Button from "@mui/material/Button";
import ButtonGroup from "@mui/material/ButtonGroup";
import PreviewIcon from "@mui/icons-material/Preview";
import DeleteIcon from "@mui/icons-material/Delete";
import Toolbar from "@mui/material/Toolbar";
import { Link } from "react-router-dom";
import HelpIcon from "@mui/icons-material/Help";
import Tooltip from "@mui/material/Tooltip";
import { Autocomplete, Table, TextField } from "@mui/material";
import { supportiveinstrumentsListGuide } from "./guide";

// datatablse packages
var $ = require("jquery");
$.DataTable = require("datatables.net");

const axios = require("axios");

const columns = [
  { id: "id", label: "Sr. No", minWidth: 40 },

  {
    id: "name",
    label: "Name",
    align: "left",
    minWidth: 40,
  },
  // {
  //   id: "distribution",
  //   label: "Distribution",
  //   align: "left",
  //   minWidth: 40,
  // },
  // {
  //   id: "sensitives",
  //   label: "Sensitives",
  //   align: "left",
  //   minWidth: 40,
  // },
  // {
  //   id: "coefficient",
  //   label: "Coefficient",
  //   align: "left",
  //   minWidth: 40,
  // },
  // {
  //   id: "percentOfOutput",
  //   label: "% Of Output",
  //   align: "left",
  //   minWidth: 40,
  // },
  // {
  //   id: "plusValue",
  //   label: "+ Value",
  //   align: "left",
  //   minWidth: 40,
  // },
];

function createData(
  id,
  name
  // distribution,
  // sensitives,
  // coefficient,
  // percentOfOutput,
  // plusValue
) {
  return {
    id,
    name,
    // distribution,
    // sensitives,
    // coefficient,
    // percentOfOutput,
    // plusValue,
  };
}

export default function SupportiveInstrumentsList() {
  const supportiveinstrumentsListGuides = supportiveinstrumentsListGuide();
  const [page, setPage] = React.useState(0);
  const [rowsPerPage, setRowsPerPage] = React.useState(25);
  const [allSupportiveInstruments, setSupportiveInstrumentsList] =
    React.useState([]);
  const [supportiveInstrumentsListData, setSupportiveInstrumentsListData] =
    React.useState([]);
    const [searchBy, setSearchBy] = React.useState("");
    const [searchKey, setSearchKey] = React.useState("");

  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(+event.target.value);
    setPage(0);
  };

  const submitSiteDelete = (id) => {
    var jsonData = {
      status: -1,
    };
    let url = BASE_URL;
    axios
      .patch(url + `standards/${id}`, jsonData)
      .then((res) => {
        toast("Supportive Instruments was deleted!");
        setTimeout(refresh, 500);
      })
      .catch((error) => {
        toast.error("Something Went Wrong!");
      });
  };

  var refresh = () => {
    window.location.reload(false);
    // <Link to="/master/master/standard">Home</Link>
  };

  // const getSupportiveInstrumentsList = (event) => {
  //   let url = BASE_URL;
  //   axios
  //     .get(url + "standards?_where=(type,eq,2)~and(status,eq,1)")
  //     .then((res) => {
  //       setSupportiveInstrumentsList(res.data);
  //       initializeDataTable();
  //     })
  //     .catch((error) => {
  //       console.log(error);
  //     });
  // };
  const search = () => {
    getSupportiveInstrumentsList();
  };
  const getSupportiveInstrumentsList = (event) => {
    let whereConditions = "";
    let pagination_settings = "";

    if (searchBy !== "" && searchKey !== "") {
      let colName = {
        "Name": "standardName",
      }[searchBy];
     
      whereConditions = `AND ${colName} like '%${searchKey}%'`;
      console.log("whereConditions",whereConditions)
    }

    if (rowsPerPage != -1) {
      pagination_settings = `limit ${rowsPerPage} offset ${
        rowsPerPage * Number(page)
      }`;
    }
    let data = {
      query: `SELECT * FROM standards WHERE type = 2 AND status = 1 ${whereConditions} ORDER BY id DESC;
      `,
    };

    axios
      .post(BASE_URL + `dynamic`, data)
      .then((res) => {
        setSupportiveInstrumentsList(res.data);
        initializeDataTable();
      })
      .catch((error) => {
        console.log(error);
      });
  };


  const initializeDataTable = () => {
    $("#datatable-keytable").DataTable({
      destroy: true,
      keys: true,
      dom:
        "<'row mb-2'<'col-sm-9' B><'col-sm-3' >>" +
        "<'row mb-2'<'col-sm-9' l><'col-sm-3' f>>" +
        "<'row'<'col-sm-12' tr>>" +
        "<'row'<'col-sm-7 mt-2 mr-5 pr-4'i><'ml-5' p>>",
      bPaginate: false,
      bLengthChange: true,
      bFilter: true,
      bInfo: false,
      bAutoWidth: true,
      language: {
        zeroRecords: " ",
      },

      buttons: ["copy", "csv", "excel", "print"],
    });
  };

  const supportInstData = () => {
    var rowDaata = [];
    for (let i = 0; i < allSupportiveInstruments.length; i++) {
      rowDaata.push(
        createData(
          allSupportiveInstruments[i].id,
          allSupportiveInstruments[i].standardName
          // allSupportiveInstruments[i].axialUniformity,
          // allSupportiveInstruments[i].radialUniformity,
          // allSupportiveInstruments[i].stability,
          // allSupportiveInstruments[i].percentOfOutput,
          // allSupportiveInstruments[i].plusValue
        )
      );
    }
    setSupportiveInstrumentsListData(rowDaata);
  };

  useEffect(() => {
    getSupportiveInstrumentsList();
  }, []);

  useEffect(() => {
    supportInstData();
  }, [allSupportiveInstruments]);

  return (
    <TableContainer component={Paper} sx={{ mt: 2 }}>
      <div style={{display:"flex",justifyContent:"space-between"}}>            
      <Toolbar>
        <Button
        id="suppt_instr_list_createbtn"
          variant="contained"
          size="small"
          component={Link}
          to="/master/supportiveInstruments/createSupportiveInstruments"
          onClick={() => {
            {
            }
          }}
        >
          <b>create SUPPORTIVE INSTRUMENTS</b>
        </Button>
      </Toolbar>
      <Tooltip title="User Guide" placement="top-start">
        <Button
         onClick={() => {
          supportiveinstrumentsListGuides.drive();
         }}
        >
          <HelpIcon />
        </Button>
      </Tooltip>
    </div>  
      <hr />
      <br />
      <div
         className="mb-2"
         style={{
           display: "flex",
           float: "left",
           justifyContent: "flex-end",
           width: "100%",
         }}
      >
      <div
        className="mb-2"
        style={{ display: "flex", float: "left", justifyContent: "flex-end", marginRight: "10px" }}
      >
        <Autocomplete
          sx={{
            m: 0,
            minWidth: 180,
            marginLeft: "10px",
          }}
          size="small"
          id="suppt_instr_list_searchby"
          options={[
            { key: "standardName", label: "Name" },
           
           
          ]}
          renderInput={(params) => <TextField {...params} label="Search By" />}
          onInputChange={(event, newInputValue) => {
            setSearchBy(newInputValue);
          }}
        />
        <TextField
          id="suppt_instr_list_searchbytext"
          label={"enter " + searchBy}
          size="small"
          variant="outlined"
          onChange={(e) => setSearchKey(e.target.value)}
        />
        <Button
        id="suppt_instr_list_searchbtn"
          variant="contained"
          size="small"
          sx={{ m: 0 }}
          style={{ marginLeft: "10px" }}
          onClick={() => {
            search();
          }}
        >
          <SearchIcon />
        </Button>
      </div>
      </div>
      <Table stickyHeader aria-label="sticky table" size="small" id="suppt_instr_list_table">
        <TableHead>
          <TableRow>
            {columns.map((column) => (
              <TableCell
                key={column.id}
                align={column.align}
                style={{ minWidth: column.minWidth }}
              >
                <b>{column.label}</b>
              </TableCell>
            ))}
            <TableCell>
              <b>Actions</b>
            </TableCell>
          </TableRow>
        </TableHead>
        <TableBody>
          {supportiveInstrumentsListData
            .slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
            .map((row, index) => {
              return (
                <TableRow hover role="checkbox" tabIndex={-1} key={index}>
                  {columns.map((column) => {
                    let value = row[column.id];

                    if (column.id == "id") {
                      value = page * rowsPerPage + index + 1;
                    }
                    return (
                      <TableCell key={column.id} align={column.align}>
                        {column.format && typeof value === "number"
                          ? column.format(value)
                          : value}
                      </TableCell>
                    );
                  })}

                  <TableCell>
                    <ButtonGroup size="small" aria-label="small button group">
                      <Tooltip
                        title="Edit Supportive Instruments"
                        placement="top-start"
                      >
                        <Button
                        id="suppt_instr_list_edit"
                          component={Link}
                          to={`/master/supportiveInstruments/EditSupportiveInstruments/${row.id}`}
                        >
                          <PreviewIcon />
                        </Button>
                      </Tooltip>
                      <Tooltip
                        title="Delete Supportive Instruments"
                        placement="top-start"
                      >
                        <Button
                        id="suppt_instr_list_delete"
                          onClick={(e) => {
                            if (window.confirm("Really want to delete Supportive Instruments?")) {
                              submitSiteDelete(row.id);
                            }
                          }}
                        >
                          <DeleteIcon style={{ color: "#dc3545" }} />
                        </Button>
                      </Tooltip>
                    </ButtonGroup>
                  </TableCell>
                </TableRow>
              );
            })}
        </TableBody>
      </Table>
      <TablePagination
        rowsPerPageOptions={[10, 25, 100]}
        component="div"
        count={supportiveInstrumentsListData.length}
        rowsPerPage={rowsPerPage}
        page={page}
        onPageChange={handleChangePage}
        onRowsPerPageChange={handleChangeRowsPerPage}
      />

      <ToastContainer />
    </TableContainer>
  );
}
