import React, { useState, useRef } from "react";
import { useNavigate, useParams } from "react-router-dom";
// import { BASE_URL } from "./../global";
import { BASE_URL } from "../../global";
import { useEffect } from "react";
// import EditTypeA from "./typeA/editTypeA";
// import EditTypeB from "./workReport/typeB/editTypeB";
// import EditBudget from "./workReport/budget/editBudget";
import { useReactToPrint } from "react-to-print";
import { ClassicTable } from "./../../utils/components/Styles";
import { isCovertPossible } from "../../utils/components/unitConvertor";
import {
  Button,
  Chip,
  Divider,
  Grid,
  Paper,
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableRow,
  Toolbar,
  Typography,
  TextField,
  CircularProgress,
  FormControl,
  InputLabel,
  OutlinedInput,
  InputAdornment,
  IconButton,
  Tooltip,
} from "@mui/material";
import InfoIcon from "@mui/icons-material/Info";
import { toast } from "react-toastify";
import {
  resolveUncertaintyValue,
  generateTableData,
  convertUnit,
  check_percentage,
  calculateUncertaintyContribution,
} from "./utils";
import { UNCERTAINTY_LINK } from "../master/staticTable/editTable";
import { hi } from "date-fns/locale";
const axios = require("axios");

const UNIT_ROW_VALUE_PREFIX = "_unit_";
const ROW_HEADER_VALUE_PREFIX = "_rh_";
const UNIT_SEPARATOR = "#";

const MEAN_PECISION = 4;
const X_MINUS_X_BAR_PECISION = 4;
const X_MINUS_X_BAR_SQUARE_PECISION = X_MINUS_X_BAR_PECISION + 4;
const SD_PRECISION = X_MINUS_X_BAR_PECISION + 4;
const MSD_PRECISION = X_MINUS_X_BAR_PECISION + 4;

const K = 2.0;

const COMBINED_UNCERTAINTY_PRECISION_COUNT = 4;
const UNCERTAINTY_PRECISION_COUNT = 4;
const EXPANDED_UNCERTAINTY_PRECISION_COUNT = 4;
const RESULT_CMC_PRECISION_COUNT = 4;

const typeA_columns = [
  {
    label: "Sr. No.",
    align: "left",
    minWidth: 40,
  },
  {
    label: "READINGS x",
    align: "left",
    minWidth: 40,
  },
  {
    label: "MEAN x̄",
    align: "left",
    minWidth: 40,
  },
  {
    label: "x-x̄",
    align: "left",
    minWidth: 40,
  },
  {
    label: (
      <>
        (x-x̄)<sup>2</sup>
      </>
    ),
    align: "left",
    minWidth: 40,
  },
];

const typeB_columns = [
  {
    label: "",
    align: "left",
    minWidth: 40,
  },
  {
    label: "Source",
    align: "left",
    minWidth: 40,
    precision: 4,
  },
  {
    label: "Distribution",
    align: "left",
    minWidth: 40,
  },
  {
    label: "DOF",
    align: "left",
    minWidth: 40,
  },
  {
    label: "Calculation",
    align: "left",
    minWidth: 40,
  },
  {
    label: "Value(±)",
    align: "left",
    minWidth: 40,
    precision: 4,
  },
];

const budget_columns = [
  {
    label: "Sources of Uncertainty",
    align: "left",
    minWidth: 40,
  },
  {
    label: "Estimate",
    align: "left",
    minWidth: 40,
    precision: 4,
  },
  {
    label: "Limits Xi",
    align: "left",
    minWidth: 40,
    precision: 4,
  },
  {
    label: "Probability Distribution",
    align: "left",
    minWidth: 40,
  },
  {
    label: "Standard Uncertainty",
    align: "left",
    minWidth: 40,
    precision: 4,
  },
  {
    label: "Sensitivity Coefficient",
    align: "left",
    minWidth: 40,
  },
  {
    label: "Uncertainty Contribution",
    align: "left",
    minWidth: 40,
    precision: 4,
  },
  {
    label: "Degrees of freedom",
    align: "left",
    minWidth: 40,
    precision: 4,
  },
];

const ShowTable = ({ headers, readings }) => {

  return (
    <ClassicTable>
      <Table sx={{ minWidth: 650 }} aria-label="simple table">
        <TableHead>
          <TableRow>
            {headers.map((e, idx) => (
              <TableCell key={`${idx}`}>
                {typeof e.label === "function" ? e.label() : e.label}
              </TableCell>
            ))}
          </TableRow>
        </TableHead>
        <TableBody>
          {readings.map((reading, idx) => (
            <TableRow key={`${idx}`}>
              {reading.map((col, id) => (
                <TableCell
                  colSpan={typeof col === "string" ? 1 : col?.[1] || 1}
                  key={`${idx}-${id}`}
                >
                  {typeof col === "string"
                    ? col?.replaceAll(UNIT_SEPARATOR, " ")
                    : typeof col === "function"
                    ? col()
                    : typeof col?.[0] === "function"
                    ? col?.[0]()
                    : col?.[0]?.replaceAll(UNIT_SEPARATOR, " ")}
                </TableCell>
              ))}
            </TableRow>
          ))}
        </TableBody>
      </Table>
    </ClassicTable>
  );
};

export default function ViewAll() {
  const { datasheetId, instrumentId, type, tableId, readingId } = useParams();

  const [uncertainties, setUncertainties] = useState(null);

  const navigate = useNavigate();

  const printComponentRef = useRef();

  const handlePrint = useReactToPrint({
    content: () => printComponentRef.current,
  });

  const convertUnitWrap = (val, from, to, V) => {
    return Number(convertUnit(Number(val), from, to, V?.[0], V?.[1]));
  };

  const finalizeUncertaintyWithCMC = (
    uncertainty,
    staticTable,
    datasheetReading,
    cmcs,
    unitMap
  ) => {
    let finalUncertainty = null;
    let uncertaintyPercentage = null;
    let invalidCmcs = false;
    let lowerCMC = null,
      higherCMC = null;

    if (staticTable) {
      let defaultConfig = staticTable["defaultConfiguration"] || {};
      let budget = defaultConfig["budget"];
      let formulas = budget["formulas"];
      let rangeCol = defaultConfig["rangeCol"];
      let uncertaintyPercentageFormula = formulas?.["uncertainty"];
      let formula = uncertaintyPercentageFormula?.["default"];

      // 1. find CMC values
      let cmcValues = cmcs.map((cmc) => ({
        fromRange: [
          cmc.fromRange[0]
            ? Number(
                convertUnitWrap(
                  Number(cmc.fromRange[0]),
                  cmc.fromRange[1],
                  unitMap[1] ? unitMap[2] : unitMap[0]
                )
              )
            : undefined,
          cmc.fromRange[1],
        ],
        toRange: [
          cmc.toRange[0]
            ? Number(
                convertUnitWrap(
                  Number(cmc.toRange[0]),
                  cmc.toRange[1],
                  unitMap[1] ? unitMap[2] : unitMap[0]
                )
              )
            : undefined,
          cmc.toRange[1],
        ],
        lowerCmc: [
          cmc.lowerCmc[0]
            ? Number(
                convertUnitWrap(
                  Number(cmc.lowerCmc[0]),
                  cmc.lowerCmc[1],
                  unitMap[1] ? unitMap[2] : unitMap[0],
                  check_percentage(
                    cmc.lowerCmc[1],
                    cmc.fromRange[0],
                    cmc.toRange[0],
                    datasheetReading[rangeCol]?.[0]
                  ) ||
                    check_percentage(
                      unitMap[1] ? unitMap[2] : unitMap[0],
                      cmc.fromRange[0],
                      cmc.toRange[0],
                      datasheetReading[rangeCol]?.[0]
                    )
                )
              )
            : undefined,
          cmc.lowerCmc[1],
        ],
        higherCmc: [
          cmc.higherCmc[0]
            ? Number(
                convertUnitWrap(
                  Number(cmc.higherCmc[0]),
                  cmc.higherCmc[1],
                  unitMap[1] ? unitMap[2] : unitMap[0],
                  check_percentage(
                    cmc.higherCmc[1],
                    cmc.fromRange[0],
                    cmc.toRange[0],
                    datasheetReading[rangeCol]?.[0]
                  ) ||
                    check_percentage(
                      unitMap[1] ? unitMap[2] : unitMap[0],
                      cmc.fromRange[0],
                      cmc.toRange[0],
                      datasheetReading[rangeCol]?.[0]
                    )
                )
              )
            : undefined,
          cmc.higherCmc[1],
        ],
      }));

      // 2. apply formula from table template onto uncertainty
      if (formula) {
        for (let i = 30; i > 0; i--) {
          let value = datasheetReading["c" + i]?.[0];
          value = convertUnitWrap(
            value,
            datasheetReading["c" + i]?.[1] || "",
            unitMap[2]
          );
          formula = formula.replaceAll("c" + i, value);
        }
        formula = formula.replaceAll("uncertainty", uncertainty);
        uncertaintyPercentage = eval(formula);
        // may be comment/uncomment below line based on testing
        // _uncertainty = convertUnitWrap(_uncertainty, unitMap[2], unitMap[0]);
      }

      uncertaintyPercentage = uncertaintyPercentage
        ? Number(uncertaintyPercentage).toFixed(
            EXPANDED_UNCERTAINTY_PRECISION_COUNT
          )
        : uncertaintyPercentage;
      uncertainty = uncertaintyPercentage || uncertainty;

      // 3. compare uncertainty with cmc values, and find final uncertainty
      finalUncertainty = uncertainty;

      if (uncertainty !== undefined && uncertainty !== null && rangeCol) {
        for (let i = 0; i < cmcValues.length; i++) {
          if (
            cmcValues[i]?.fromRange?.[0] === undefined ||
            cmcValues[i]?.toRange?.[0] === undefined
          ) {
            invalidCmcs = true;
            continue;
          }
          if (
            !isCovertPossible(
              cmcValues[i]?.fromRange?.[1],
              unitMap[1] ? unitMap[2] : unitMap[0]
            ) ||
            !isCovertPossible(
              cmcValues[i]?.toRange?.[1],
              unitMap[1] ? unitMap[2] : unitMap[0]
            )
          )
            continue;
          if (
            cmcValues[i]?.fromRange?.[0] <= datasheetReading[rangeCol]?.[0] &&
            cmcValues[i]?.toRange?.[0] + 0.5 > datasheetReading[rangeCol]?.[0]
          ) {
            lowerCMC =
              cmcValues[i].lowerCmc[0] + "#" + cmcValues[i].lowerCmc[1];
            higherCMC =
              cmcValues[i].higherCmc[0] + "#" + cmcValues[i].higherCmc[1];

            if (cmcValues[i].higherCmc[0]) {
              if (
                (cmcValues[i]?.lowerCmc[0] == undefined ||
                  cmcValues[i]?.lowerCmc[0] == null ||
                  cmcValues[i]?.lowerCmc[0] == "") &&
                cmcValues[i].higherCmc[0] > uncertainty
              ) {
                finalUncertainty = cmcValues[i]?.higherCmc[0];
              } else if (
                cmcValues[i]?.lowerCmc[0] &&
                cmcValues[i].lowerCmc[0] > uncertainty
              ) {
                finalUncertainty = cmcValues[i]?.lowerCmc[0];
              }
              break;
            } else {
              if (cmcValues[i]?.lowerCmc[0] > uncertainty) {
                finalUncertainty = cmcValues[i]?.lowerCmc[0];
              }
              break;
            }
          }
        }
        if (invalidCmcs) {
          toast.warning("Please Correct the CMC values");
        }
      } else {
        toast.warning(
          "Please set Range Column using Template, to compare cmc!"
        );
      }
    }
    return [
      uncertaintyPercentage,
      Number(finalUncertainty).toFixed(UNCERTAINTY_PRECISION_COUNT),
      lowerCMC,
      higherCMC,
    ];
  };

  const calcUncertainty = (
    uncertaintyFactorRows,
    cmcs,
    staticTable,
    datasheetReading,
    unitMap
  ) => {
    // 1. calculate sum of uncertainty contribution
    let sumOfUncertaintyContribution = 0;
    uncertaintyFactorRows?.forEach((element) => {
      let uncertaintyContribution = element[element.length - 2];
      let [uncertaintyContributionValue, uncertaintyContributionUnit] =
        uncertaintyContribution?.split("#");
      uncertaintyContributionValue = Number(uncertaintyContributionValue);
      sumOfUncertaintyContribution += Math.pow(uncertaintyContributionValue, 2);
    });

    // 2. calculate combined uncertainty
    let combinedUncertainty = Number(
      Math.sqrt(sumOfUncertaintyContribution).toFixed(
        COMBINED_UNCERTAINTY_PRECISION_COUNT
      )
    );

    // 3. calculate expanded uncertainty
    let expandedUncertainty = Number(combinedUncertainty * K).toFixed(
      UNCERTAINTY_PRECISION_COUNT
    );

    let [uncertaintyPercentage, finalExpandedUncertainty, lowerCMC, higherCMC] =
      finalizeUncertaintyWithCMC(
        expandedUncertainty,
        staticTable,
        datasheetReading,
        cmcs,
        unitMap
      );
    return [
      combinedUncertainty,
      expandedUncertainty,
      uncertaintyPercentage,
      finalExpandedUncertainty,
      lowerCMC,
      higherCMC,
    ];
  };

  const resolveUncertaintyRows = (
    standards,
    uncertaintyFactors,
    uncertaintiesMap,
    id,
    fallbackUnit,
    supportiveRanges,
    readings,
    rels,
    rangeCol
  ) => {
    let rows = [];
    for (let i = 0; i < standards.length; i++) {
      for (let j = 0; j < uncertaintyFactors.length; j++) {
        if (
          uncertaintiesMap[standards[i][0]]?.includes(
            `${uncertaintyFactors[j].id}`
          )
        ) {
          // 1. resolve source and standard coefficient formulas
          let value = resolveUncertaintyValue(
            uncertaintyFactors[j],
            {
              standard: standards[i][0],
              standardRange: standards[i][1],
              supportiveInstrumentRange: supportiveRanges?.id,
              datasheet: datasheetId,
              instrument: instrumentId,
              datasheeetStaticTable: tableId,
              datasheetStaticReading: id,
            },
            fallbackUnit,
            readings[rangeCol]
          );

          // 2. if factor is linked to reading column the take value+unit from reading row
          if (rels[uncertaintyFactors[j].id]) {
            let _value = readings[rels[uncertaintyFactors[j].id]][0];
            _value = String(_value).replace(/[$]+/, "")
            value[0] = `${
              _value +
              "#" +
              readings[rels[uncertaintyFactors[j].id]][1]
            }`;
          }

          rows.push([uncertaintyFactors[j], ...value]);
        }
      }
    }
    if (supportiveRanges) {
      ["axialUniformity", "radialUniformity", "stability"].map((p) => {
        let ranges = supportiveRanges["rangeName"]
          ?.split("|")
          ?.map((e) => e?.split("#"));
        supportiveRanges[p] &&
          rows.push([
            {
              name: `${p} (${supportiveRanges?.standardName} : ${supportiveRanges?.stId})`,
              distribution: "1/√3",
              sensitives: 1,
              formula: 1.7320508075688772,
            },
            supportiveRanges[p],
            "1",
          ]);
      });
    }

    return rows;
  };

  const processTypeARows = ({ inputs, unit }) => {
    if (!inputs) return {};
    inputs = inputs.filter((e) => e !== null && e !== undefined);

    if (!inputs || inputs?.length <= 0) return {};

    let mean = inputs.reduce((s, v) => s + v, 0) / inputs.length;
    mean = mean.toFixed(MEAN_PECISION);
    let rows = [];

    let x_xbar_sqr_sum = 0;
    inputs.map((e, i) => {
      rows.push([
        `${i + 1}`,
        `${e} ${unit}`,
        `${mean} ${unit}`,
        (e - mean).toFixed(X_MINUS_X_BAR_PECISION),
        Math.pow(e - mean, 2).toFixed(X_MINUS_X_BAR_SQUARE_PECISION),
      ]);
      x_xbar_sqr_sum += Number(rows[rows.length - 1][4]);
    });
    x_xbar_sqr_sum = x_xbar_sqr_sum.toFixed(X_MINUS_X_BAR_SQUARE_PECISION);

    let sd = Math.sqrt(x_xbar_sqr_sum / (inputs.length - 1));
    sd = sd.toFixed(SD_PRECISION);
    let meanOfStdDev = sd / Math.sqrt(rows.length);
    meanOfStdDev = meanOfStdDev.toFixed(MSD_PRECISION);
    rows.push([
      ["", 3],
      () => (
        <>
          (x-x̄)<sup>2</sup>
        </>
      ),
      x_xbar_sqr_sum,
    ]);
    rows.push([["STANDARD DEVIATION OF THE MEAN (σ)", 4], `${sd} ${unit}`]);
    rows.push([["MEAN OF STANDARD DEVIATION", 4], `${meanOfStdDev} ${unit}`]);
    rows.push([["DEGREES OF FREEDOM", 4], `${inputs.length - 1}`]);
    return { rows, mean, sd, meanOfStdDev, n: inputs.length };
  };

  const processTypeBRows = ({
    id,
    inputs,
    unit: fallbackUnit,
    supportiveRanges,
    uncertaintiesMap,
    staticTable,
    readings,
  }) => {
    if (!inputs) return {};

    let reverseMap = {};
    let standards = readings.standardRanges.map((e) => e);
    let typebRels = { ...staticTable.defaultConfiguration.typeb.relations };
    let rangeCol = (staticTable["defaultConfiguration"] || {})?.["rangeCol"];

    Object.keys(typebRels).map((e) => (reverseMap[typebRels[e]] = e));

    let uncertaintyFactorRows = resolveUncertaintyRows(
      standards,
      inputs,
      uncertaintiesMap,
      id,
      fallbackUnit,
      supportiveRanges,
      readings,
      reverseMap,
      rangeCol
    );
    return {
      rows: uncertaintyFactorRows.map((uf, i) => {
        let uncertaintyFactor = uf[0];
        let uncertaintyFactorValue = uf[1];
        let sensitiveCoefficient = uf[2];

        return [
          `U${i + 1}`,
          () => (
            <>
              {uncertaintyFactor.name}
              <br />
              {`${uncertaintyFactorValue?.replaceAll("#", " ")}`}
            </>
          ),
          uncertaintyFactor.distribution,
          "ω",
          `Sensitivity Coeff. = ${sensitiveCoefficient}`,
          `${uncertaintyFactorValue}`,
        ];
      }),
    };
  };

  const processBudgetRows = ({
    id,
    inputs,
    unit,
    supportiveRanges,
    uncertaintiesMap,
    typeA,
    cmcs,
    staticTable,
    readings,
  }) => {
    if (!inputs) return {};

    let reverseMap = {};

    let standards = readings.standardRanges.map((e) => e);
    let typebRels = { ...staticTable.defaultConfiguration.typeb.relations };
    let rangeCol = (staticTable["defaultConfiguration"] || {})?.["rangeCol"];
    Object.keys(typebRels).map((e) => (reverseMap[typebRels[e]] = e));
    let uncertaintyFactorRows = resolveUncertaintyRows(
      standards,
      inputs,
      uncertaintiesMap,
      id,
      unit[1] ? unit[2] : unit[0],
      supportiveRanges,
      readings,
      reverseMap,
      rangeCol
    );

    let rows = uncertaintyFactorRows.map((uf, i) => {
      let uncertaintyFactor = uf[1];
      let [ufValue, ufUit] = uncertaintyFactor?.split("#") || [null, null];
      ufValue = Number(ufValue);

      let sensitiveCoefficient = uf[2];

      let ufLimitXi =
        uf[0].name.toLowerCase().trim()?.includes("resolution") ||
        uf[0].name.toLowerCase().trim()?.includes("leastcount") ||
        uf[0].name.toLowerCase().trim()?.includes("least count")
          ? `${ufValue / 2}`
          : ufValue;
      ufLimitXi = Number(ufLimitXi);

      let uncertaintyContribution = calculateUncertaintyContribution(
        ufLimitXi + `#${ufUit}`,
        Number(uf[0].formula),
        sensitiveCoefficient,
        budget_columns[i]?.precision,
        unit[1] ? unit[2] : unit[0]
      );

      return [
        () => (
          <>
            U{i + 1}
            <br />
            {uf[0].name}
          </>
        ),
        uncertaintyFactor,
        Number(ufLimitXi).toFixed(budget_columns[i]?.precision || 7) +
          " " +
          ufUit,
        uf[0].distribution,
        `${Number(ufLimitXi / uf[0].formula).toFixed(
          budget_columns[i]?.precision || 4
        )}` +
          " " +
          ufUit,
        `${sensitiveCoefficient}`,
        `${uncertaintyContribution}`,
        "∞",
      ];
    });

    if (typeA.meanOfStdDev) {
      rows = rows.concat([
        [
          "Repeatability",
          `${Number(typeA.sd).toFixed(budget_columns[1]?.precision || 4)}`,
          `${Number(typeA.sd).toFixed(budget_columns[2]?.precision || 4)}`,
          `√${typeA.n - 1}`,
          `${Number(typeA.meanOfStdDev).toFixed(
            budget_columns[4]?.precision || 4
          )}`,
          `1`,
          `${Number(typeA.meanOfStdDev).toFixed(
            budget_columns[6]?.precision || 4
          )}`,
          `${Number(
            (Math.pow(0.440820639 * 2, 4) * (typeA.n - 1)) / Math.pow(0.012, 4)
          ).toFixed(budget_columns[7]?.precision || 4)}`,
        ],
      ]);
    }

    let [
      combinedUncertainty,
      uncertainty,
      uncertaintyPercentage,
      finalExpandedUncertainty,
      lowerCMC,
      higherCMC,
    ] = calcUncertainty(rows, cmcs, staticTable, readings, unit);

    rows = rows.concat([
      [
        "",
        `Uc`,
        ``,
        ``,
        ``,
        ``,
        `${Number(combinedUncertainty).toFixed(
          budget_columns[6]?.precision || 4
        )}`,
        `${typeA.n ? typeA.n - 1 : 0}`,
      ],
      [
        "",
        `Expanded Uncertainty`,
        `K=`,
        `${K}`,
        ``,
        ``,
        `${Number(combinedUncertainty * 2).toFixed(
          budget_columns[6]?.precision || 4
        )}`,
        `∞`,
      ],
    ]);
    return {
      rows,
      extra: {
        uc: combinedUncertainty,
        freedom: typeA.n - 1,
        uncertainty,
        uncertaintyPercentage,
        finalExpandedUncertainty,
        lowerCMC,
        higherCMC,
      },
    };
  };

  const buildTypeA = (rows, second) => {
    return (
      <Paper sx={{ mx: 5, mt: 2, p: 2 }}>
        <Divider sx={{ mb: 1, mt: 2 }}>
          <Chip
            label={`TYPE-A ${second ? 2 : 1} EVALUATION`}
            style={{ fontWeight: "bold" }}
          />
        </Divider>
        <ShowTable headers={typeA_columns} readings={rows} />
        <Grid
          container
          spacing={2}
          sx={{ mt: 2 }}
          style={{ marginTop: "80px" }}
        >
          <Grid item xs={4}>
            <Typography align="center">Calibrated By</Typography>
          </Grid>
          <Grid item xs={4}></Grid>
          <Grid item xs={4}>
            <Typography align="center">Approved By</Typography>
          </Grid>
        </Grid>
      </Paper>
    );
  };

  const buildTypeB = (rows) => {
    return (
      <Paper sx={{ mx: 5, mt: 2, p: 2 }}>
        <Divider sx={{ mb: 1, mt: 2 }}>
          <Chip
            label="Type-B Contribution
          "
            style={{ fontWeight: "bold" }}
          />
        </Divider>
        <ShowTable headers={typeB_columns} readings={rows} />
      </Paper>
    );
  };

  const buildBudget = (rows, params) => {
    return (
      <Paper sx={{ mx: 5, mt: 2, p: 2 }}>
        <Divider sx={{ mb: 1, mt: 2 }}>
          <Chip label="Uncertainty Budget" style={{ fontWeight: "bold" }} />
        </Divider>

        <ShowTable headers={budget_columns} readings={rows} />

        <Grid container spacing={2} sx={{ my: 2 }}>
          <Grid item xs={3}>
            <TextField
              id="outlined-basic"
              label="COMBINED UNCERTAINTY (Uc)"
              value={params?.uc}
              size="small"
              fullWidth
              variant="outlined"
              disabled
            />
          </Grid>
          <Grid item xs={3}>
            <TextField
              id="outlined-basic"
              label="EFFECTIVE DEGREES OF FREEDOM"
              value={params?.freedom}
              size="small"
              fullWidth
              variant="outlined"
              disabled
            />
          </Grid>
          {params?.uncertaintyPercentage !== null &&
          params?.uncertaintyPercentage !== undefined ? (
            <Grid item xs={3}>
              <TextField
                id="outlined-basic"
                label="EXPANDED UNCERTAINTY (%)"
                value={params?.uncertaintyPercentage}
                size="small"
                fullWidth
                variant="outlined"
                disabled
              />
            </Grid>
          ) : (
            <Grid item xs={3}>
              <FormControl variant="outlined" fullWidth size="small">
                <InputLabel htmlFor="outlined-adornment-password">
                  Expanded Uncertainty
                </InputLabel>
                <OutlinedInput
                  id="outlined-adornment-password"
                  type={"text"}
                  value={params?.finalExpandedUncertainty}
                  endAdornment={
                    <InputAdornment position="end">
                      <Tooltip
                        title={
                          <div style={{ fontSize: "12px" }}>
                            calculated uncertainty: {params?.uncertainty}
                            <br />
                            lower CMC: {params?.lowerCMC}
                            <br />
                            higher CMC: {params?.higherCMC}
                          </div>
                        }
                      >
                        <IconButton>
                          <InfoIcon />
                        </IconButton>
                      </Tooltip>
                    </InputAdornment>
                  }
                  label="Expanded Uncertainty "
                />
              </FormControl>

            </Grid>
          )}

          {/* 
            TODO: remove below code block once View all is completed
          {(params?.uncertaintyPercentage || params?.uncertainty) && (
            <Grid item xs={3}>
              <TextField
                id="outlined-basic"
                label={`RESULT CMC ${
                  params?.uncertaintyPercentage !== null &&
                  params?.uncertaintyPercentage !== undefined
                    ? "%"
                    : ""
                }`}
                value={params?.resultCmc}
                size="small"
                fullWidth
                variant="outlined"
                disabled
              />
            </Grid>
          )} */}
        </Grid>
      </Paper>
    );
  };

  const getTypeAColumns = (table, second) => {
    return Object.entries(table)
      .map((map) => {
        if (
          (second && String(map[1]).match(/d+\d+/g)) ||
          (!second && String(map[1]).match(/m+\d+/g))
        ) {
          return map[0];
        }
      })
      .filter((val) => {
        if (val) return val;
      });
  };

  const processReportRows = async (table, readings, uncertaintiesMap, cmcs) => {
    // fun: process and preapre data for typeA, typeB and budget

    let tableA1 = [];
    let tableA2 = [];
    let staticTable = parseTable(table);
    let parsedReadings = parseReadings(readings);
    let supportiveRanges = (
      await fetchSupprtiveRanges(
        parsedReadings.map((reading) => reading.supportiveRanges)
      )
    )[0]?.[1];
    supportiveRanges = supportiveRanges || [];
    let uncertaintyFactors = (
      await fetchUncertaintyFactors(parsedReadings, uncertaintiesMap)
    )[0][1];
    cmcs = parseCmcs(cmcs);
    if (staticTable) {
      tableA1 = getTypeAColumns(staticTable);
      tableA2 = getTypeAColumns(staticTable, true);
    }

    let uncertainties = [];
    if (parsedReadings.length > 0) {
      await generateTableData(
        uncertaintyFactors,
        parsedReadings,
        tableId,
        datasheetId,
        instrumentId,
        uncertaintiesMap
      );

      let percetage_formula = (staticTable["defaultConfiguration"] || {})?.[
        "budget"
      ]?.["formulas"]?.["uncertainty"]?.["default"];

      uncertainties = await Promise.all(
        parsedReadings.map(async (readingRow, i) => {
          let uncertaintyUnit =
            readingRow.unitMap?.uncertainty?.split(UNIT_ROW_VALUE_PREFIX)[1] ||
            "";
          let rangeCol =
            (staticTable["defaultConfiguration"] || {})?.["rangeCol"] || "";
          let rangeColUnit =
            readingRow.unitMap?.[rangeCol]?.split(UNIT_ROW_VALUE_PREFIX)[1] ||
            "";

          let typeA1 = processTypeARows({
            inputs: tableA1
              .map((a) => readingRow[a][0])
              ?.filter((e) => !isNaN(e)),
            unit:
              tableA1.length > 0
                ? readingRow.unitMap[tableA1[0]]?.split(
                    UNIT_ROW_VALUE_PREFIX
                  )?.[1]
                : "",
          });

          let typeA2 = processTypeARows({
            inputs: tableA2
              .map((a) => readingRow[a][0])
              ?.filter((e) => !isNaN(e)),
            unit:
              tableA2.length > 0
                ? readingRow.unitMap[tableA2[0]]?.split(
                    UNIT_ROW_VALUE_PREFIX
                  )?.[1]
                : "",
          });

          let typeB = readingRow.standardRanges?.length
            ? processTypeBRows({
                id: `${readingRow.id}`,
                inputs: uncertaintyFactors,
                fallbackUnit: rangeColUnit,
                supportiveRanges: readingRow.supportiveRanges[1]
                  ? supportiveRanges[readingRow.supportiveRanges[1]]
                  : undefined,
                uncertaintiesMap: uncertaintiesMap,

                staticTable,
                readings: readingRow,
              })
            : {};

          let budget = readingRow.standardRanges?.length
            ? processBudgetRows({
                id: `${readingRow.id}`,
                inputs: uncertaintyFactors,
                unit: [
                  readingRow.unitMap?.uncertainty?.split(
                    UNIT_ROW_VALUE_PREFIX
                  )[1] || "",
                  percetage_formula ? true : false,
                  tableA1.length > 0
                    ? readingRow.unitMap[tableA1[0]]?.split(
                        UNIT_ROW_VALUE_PREFIX
                      )?.[1]
                    : "",
                ],
                supportiveRanges: readingRow.supportiveRanges[1]
                  ? supportiveRanges[readingRow.supportiveRanges[1]]
                  : undefined,
                uncertaintiesMap: uncertaintiesMap,
                typeA: typeA2?.meanOfStdDev
                  ? typeA1.meanOfStdDev > typeA2.meanOfStdDev
                    ? typeA1
                    : typeA2
                  : typeA1,
                cmcs,
                staticTable,
                readings: readingRow,
              })
            : {};

          return [
            [
              tableA1?.length > 0 ? typeA1.rows : [],
              tableA2?.length > 0 ? typeA2.rows : [],
            ],
            typeB.rows,
            [budget.rows, budget.extra],
            readingRow.id,
          ];
        })
      );
    }
    return uncertainties;
  };

  const parseTable = (table) => {
    let parsedTable = { ...table };
    parsedTable.conditionFormatting = JSON.parse(table.conditionFormatting);
    parsedTable.customPrecision = JSON.parse(table.customPrecision);
    parsedTable.defaultConfiguration = JSON.parse(table.defaultConfiguration);
    return parsedTable;
  };

  const fetchTables = () => {
    return axios
      .get(BASE_URL + `datasheetStaticTables?_where=(id,eq,${tableId})`)
      .then((res) => [["table", res.data[0]]])
      .catch((err) =>
        errorFn("datasheetStaticTables data fetching error : ", err)
      );
  };

  const parseReadings = (readings) => {
    let cu = {};
    let parsedReadings = [];
    let index = 0;
    readings.map((e) => {
      if (e["c1"]?.includes(UNIT_ROW_VALUE_PREFIX)) cu[e.tableId] = e;
      if (
        e["c1"]?.includes(UNIT_ROW_VALUE_PREFIX) ||
        e["c1"]?.includes(ROW_HEADER_VALUE_PREFIX)
      )
        return null;

      parsedReadings.push({});

      parsedReadings[index].unitMap = cu[e.tableId];

      Object.keys(e).map((e2, i) => {
        if ((e2.match(/c+\d+/g) || e2 === "uncertainty") && e[e2]) {
          let [value, unit] = e[e2].split("#");
          value = value.replace(/[$]+/, "")
          value = isNaN(value) || value === "" ? null : Number(value);
          parsedReadings[index][e2] = [value, unit];
        } else if (e2 === "standardRanges") {
          parsedReadings[index]["standardRanges"] = e[e2]
            ? e[e2].split(",").map((e3) => e3.split(":"))
            : [];
        } else if (e2 === "supportiveRanges") {
          parsedReadings[index]["supportiveRanges"] = e[e2]
            ? e[e2].split(":")
            : [];
        } else parsedReadings[index][e2] = e[e2];
      });
      index += 1;
    });
    return parsedReadings;
  };

  const fetchReadings = () => {
    return axios
      .get(
        BASE_URL +
          `datasheetStaticReadings?_where=(datasheetId,eq,${datasheetId})~and(tableId,eq,${tableId})${
            type === 1 ? `(is,eq,${readingId})` : ""
          }`
      )
      .then((res) => [["reading", res.data]])
      .catch((err) =>
        errorFn("datasheetStaticReadings data fetching error : ", err)
      );
  };

  const fetchUncertaintyFactors = async (readings, uncertaintiesMap) => {
    let standardsArr = readings
      .map((reading) => reading.standardRanges)
      .flat()
      .map((e) => e[0]);
    let uf = Object.entries(uncertaintiesMap)
      .filter((e) => standardsArr.includes(e[0]))
      .map((e) => e[1])
      .flat()
      .join(",");
    return axios
      .get(BASE_URL + `uncertainty?_where=(id,in,${uf})`)
      .then(async (res) => [["uncertainty", res.data]])
      .catch((err) =>
        errorFn("uncertainty factors data fetching error: ", err)
      );
  };

  const fetchUncertaintiesMap = () => {
    return axios
      .get(
        BASE_URL +
          `instruments?_fields=uncertaintyFactors&_where=(id,eq,${instrumentId})`
      )
      .then((res) => {
        let uMap = {};
        (res.data[0]?.uncertaintyFactors || "")
          .split("|")
          .map((e) =>
            e.split(":").map((e2, i) => (i === 0 ? e2 : (e2 || "").split(",")))
          )
          .map((e) => (uMap[e[0]] = e[1]));
        return [["uncertaintiesMap", uMap]];
      })
      .catch((err) => errorFn("instrument data fetching error : ", err));
  };

  const fetchSupprtiveRanges = (supportives) => {
    let ids = supportives?.map((s) => s[1]).filter((e) => e);
    if (!ids || ids.length <= 0) return [];
    return axios
      .get(
        BASE_URL +
          `xjoin?_join=s.standards,_j,sr.standardRanges&_on1=(s.id,eq,sr.standardId)&_where=(sr.id,in,${ids})&_fields=sr.id,sr.axialUniformity,sr.radialUniformity,sr.stability,s.standardName,s.stId`
      )
      .then((res) => {
        let srMap = {};
        res.data.map(
          (e) =>
            (srMap[e.sr_id] = {
              id: e.sr_id,
              axialUniformity: e.sr_axialUniformity,
              radialUniformity: e.sr_radialUniformity,
              stability: e.sr_stability,
              standardName: e.s_standardName,
              stId: e.s_stId,
            })
        );
        return [["supportiveRanges", srMap]];
      })
      .catch((err) => [errorFn("instrument data fetching error : ", err)]);
  };

  const errorFn = (errTxt, err) => {
    toast.error(errTxt + err);
    console.error(errTxt, err);
    return ["err", err];
  };

  const parseCmcs = (cmcs) => {
    let newCmcs = [];
    cmcs.map((cmc) =>
      newCmcs.push({
        fromRange: (cmc.fromRange || "").split(UNIT_SEPARATOR),
        toRange: (cmc.toRange || "").split(UNIT_SEPARATOR),
        lowerCmc: (cmc.lowerCmc || "").split(UNIT_SEPARATOR),
        higherCmc: (cmc.higherCmc || "").split(UNIT_SEPARATOR),
        id: cmc.id,
        instrumentId: cmc.instrumentId,
        mode: cmc.mode,
        parameter: cmc.parameter,
        paratype: cmc.paratype,
      })
    );
    return newCmcs;
  };

  const fetchCmcs = async () => {
    return axios
      .get(BASE_URL + `cmc?_where=(instrumentId,eq,${instrumentId})`)
      .then((res) => [["cmcs", res.data]]);
  };

  const fetchData = async () => {
    let data = {};
    let arrayData = [
      ...(await fetchTables()),
      ...(await fetchReadings()),
      ...(await fetchUncertaintiesMap()),
      ...(await fetchCmcs()),
    ];
    arrayData.map((e) => (data[e[0]] = e[1]));
    if (!data.reading || data.reading?.length == 0) {
      window.confirm("Please add units and reading rows first!");
      navigate(`/datasheet/edit/${datasheetId}/${instrumentId}`);
      window.location.reload(false);
    }
    data.parsedReadings = parseReadings(data.reading);

    for (let e of data.parsedReadings) {
      if (!e.unitMap) {
        window.confirm("Please add unit row for each reading row!");
        navigate(`/datasheet/edit/${datasheetId}/${instrumentId}`);
        window.location.reload(false);
      }
    }

    arrayData.map((e) => (data[e[0]] = e[1]));

    let preparedReportRows = await processReportRows(
      data.table,
      data.reading,
      data.uncertaintiesMap,
      data.cmcs
    );

    await Promise.all([
      ...preparedReportRows.map((uncertainty) => {
        let input = {};
        if (
          uncertainty?.[2]?.[1]?.finalExpandedUncertainty !== undefined &&
          uncertainty?.[2]?.[1]?.finalExpandedUncertainty !== null
        )
          input.uncertainty = uncertainty?.[2]?.[1]?.finalExpandedUncertainty;
        Object.entries(data.table).map((col) => {
          if (
            `${col[1]}`.includes(UNCERTAINTY_LINK) &&
            uncertainty[0] &&
            (uncertainty[0][0] || uncertainty[0][1]) &&
            (uncertainty[0][0].length > 0 || uncertainty[0][1].length > 0) &&
            (uncertainty[0][0]?.[uncertainty[0][0].length - 2]?.[1]?.replaceAll(
              " ",
              "#"
            ) ||
              uncertainty[0][1]?.[
                uncertainty[0][1].length - 2
              ]?.[1]?.replaceAll(" ", "#"))
          ) {
            if (
              `${col[1]}`.split(UNCERTAINTY_LINK)[1].toLowerCase() === "typea1"
            )
              input[`${col[0]}`] = uncertainty[0][0]?.[
                uncertainty[0][0].length - 2
              ]?.[1]?.replaceAll(" ", "#");
            else if (
              `${col[1]}`.split(UNCERTAINTY_LINK)[1].toLowerCase() === "typea2"
            )
              input[`${col[0]}`] = uncertainty[0][1]?.[
                uncertainty[0][1].length - 2
              ]?.[1]?.replaceAll(" ", "#");
          }
        });
        return Object.keys(input).length > 0
          ? axios.patch(
              BASE_URL +
                `datasheetStaticReadings/${
                  uncertainty[uncertainty.length - 1]
                }`,
              input
            )
          : [];
      }),
    ]);

    setUncertainties([...preparedReportRows]);
  };

  useEffect(() => {
    fetchData();
  }, []);

  if (!uncertainties)
    return (
      <div
        style={{
          width: "100%",
          height: "100%",
          display: "flex",
          flexDirection: "row",
          justifyContent: "center",
          alignItems: "center",
        }}
      >
        <CircularProgress size={350} thickness={2}></CircularProgress>
      </div>
    );

  if (uncertainties)
    return (
      <>
        {uncertainties &&
          uncertainties.map((u, idx) => (
            <div ref={printComponentRef} key={`uncertainty-${idx}`}>
              {u[0]?.[0]?.length > 0 ? (
                <>
                  <div>{buildTypeA(u[0][0])}</div>
                  <Divider
                    sx={{ mb: 1, mt: 2, displayPrint: "none" }}
                  ></Divider>
                </>
              ) : (
                ""
              )}
              {u[0]?.[1]?.length > 0 ? (
                <>
                  <div>{buildTypeA(u[0][1], true)}</div>
                  <Divider
                    sx={{ mb: 1, mt: 2, displayPrint: "none" }}
                  ></Divider>
                </>
              ) : (
                ""
              )}
              <div>{u[1] && buildTypeB(u[1])}</div>
              <div>{u[2][0] && buildBudget(u[2][0], u[2][1])}</div>
            </div>
          ))}
        {uncertainties.length > 0 && (
          <Toolbar
            sx={{ displayPrint: "none" }}
            style={{ padding: "0px", width: "100%" }}
          >
            <Button
              variant="contained"
              size="small"
              sx={{ ml: 3, displayPrint: "none" }}
              onClick={handlePrint}
            >
              Print
            </Button>
          </Toolbar>
        )}
      </>
    );
}
